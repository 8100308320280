import firebase from 'firebase';

firebase.initializeApp({
    // copy and paste your firebase credential here
    apiKey: "AIzaSyBe6nRLERCg_hFQd3taxQrLeEPbfB1dSc0",
    authDomain: "occupanycounter.firebaseapp.com",
    databaseURL: "https://occupanycounter-default-rtdb.firebaseio.com",
    projectId: "occupanycounter",
    storageBucket: "occupanycounter.appspot.com",
    messagingSenderId: "93217804960",
    appId: "1:93217804960:web:a6d09db050ca2cfa51efc5",
    measurementId: "G-PXK3D57Y9W"

});

firebase.analytics();

